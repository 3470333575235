
import { isNil } from 'lodash';

import {
  DelegateResourcesPayload,
  MakeClassificationReviewDecisionBody,
  UarClassificationReviewResource,
  UarClassificationReviewService,
  UarClassificationExceptionResource,
  MakeClassificationExceptionDecisionBody,
  UarClassificationExceptionPayload,
  AddIterationRequest,
  UarIteration,
  DelegateUserPermissions,
  ManagerReviewDecisionPayload,
  UarManagerReview,
  DelegateRevocationPermissions,
  RevocationReviewDecisionPayload,
  RevocationReviewExceptionDecisionItem,
  UarPermissionAccessDetails,
  UarPermissionAccessDetailsPayload,
  UarRevocationReview,
  UarPermissions,
  AddMissingResourceRequest,
  MissingResource,
  ValidateResourceRequest,
  ResourceInformation,
  AddClassificationAttestBody,
  UpdateManualResourceRequest,
  DeleteResourceRequest,
  ValidateResourceResponse,
  ManualResource,
  UARUIString,
  ClassificationReviewAttachment,
  AthenaIterationStageName,
  UarClassificationReviewServiceDecision,
  UarRevocationExceptionPayload,
  UarRevocationReviewAdditionalDetailsPayload,
  UarRevocationReviewAdditionalDetails,
  UarManagerReviewPreviousDecision,
  UarMetaInfo
} from '../../models';
import { UserAccount } from '../../models/Forms/UserAccount';
import {
  AthenaManualReviewActionItem,
  ManualReviewAttachment,
  UarGetManualReviewAttachmentRequestPayload,
  UarGetManualReviewItemRequestPayload,
  UarUpdateManualReviewEvidenceRequestPayload,
  UarDelegateManualReviewItemsRequestPayload,
  UarSkipManualReviewEvidenceRequestPayload
} from '../../models/UARModels/ManualReview';
import {
  AthenaTriageFilterField,
  UarEvidenceInspectionDecisionPayload
} from '../../models/UARModels/ManualReviewTriage';
import { AthenaMyReviewResult } from '../../models/UARModels/MyReview';

import { UarService } from '../../models/UARModels/Services';
import { parseTriageFilterFields } from '../../utils';
import {
  get,
  post
} from '../OdataApiService';
import { buildODataQuery } from '../utils/odataQueryBuilder';

// ---------------------------------------------------------------------------------------------------------------------

export const GET_REVIEW_ITERATION_INFO = '/data/UarIterationDetails';
export const POST_ITERATION_REQUEST = '/data/AddUarIterationRequest';

export const POST_MISSING_RESOURCE_REQUEST = '/data/AddResourceRequest';
export const POST_UPDATE_RESOURCE_REQUEST = '/data/UpdateResourceRequest';
export const POST_DELETE_RESOURCE_REQUEST = '/data/DeleteResourceRequest';
export const POST_Validate_RESOURCE_REQUEST = '/data/SearchResourceRequest';
export const GET_RESOURCE_INFOMATION_REQUEST = '/data/GetResourceTypeDetailsRequest';

export const GET_MANUAL_RESOURCE = '/data/ManualResource';
export const GET_CLASSIFICATION_REVIEW_SERVICES = '/data/ClassificationReviewService';
export const GET_CLASSIFICATION_REVIEW_SERVICES_ASSIGN_TO = '/data/ClassificationReviewServiceByAssignTo';
export const GET_CLASSIFICATION_REVIEW_RESOURCES = '/data/ClassificationReviewResource';
export const DELEGATE_CLASSIFICATION_REVIEW_RESOURCES = '/data/DelegateClassificationReviewItemsRequest';
export const MAKE_CLASSIFICATION_REVIEW_DECISION = '/data/SetClassificationReviewResultRequest';
export const GET_CLASSFICATION_REVIEW_ATTACHMENT = '/data/ClassificationReviewAttachment';

export const GET_MANAGER_REVIEW = '/data/ManagerReviewPermission';
export const DELEGATE_USER_PERMISSIONS = '/data/DelegateManagerReviewItemsRequest';
export const MAKE_MANAGER_REVIEW_DECISION = '/data/SetManagerReviewPermissionDecisionRequest';

export const GET_REVOCATION_REVIEW = '/data/RevocationReviewPermission';
export const DELEGATE_REVOCATION_PERMISSIONS = '/data/DelegateRevocationReviewItemsRequest';
export const MAKE_REVOCATION_REVIEW_DECISION = '/data/SetRevocationReviewResultRequest';
export const GET_REVOCATION_REVIEW_PERMISSION_ACCESS_PATHS_REQUEST = 'data/GetRevocationReviewPermissionAccessPathsRequest';
export const GET_REVOCATION_REVIEW_ADDITIONAL_DETAILS_REQUEST = 'data/GetRevocationReviewAdditionalDetailsRequest';

export const GET_CR_EXCEPTION = '/data/ClassificationReviewException';
export const MAKE_CR_EXCEPTION_DECISION = '/data/SetClassificationReviewExceptionDecisionRequest';

export const GET_RR_EXCEPTION = '/data/RevocationReviewException';
export const MAKE_RR_EXCEPTION_DECISION = '/data/SetRevocationReviewExceptionDecisionRequest';

export const GET_USER_PERMISSIONS_REQUEST = '/data/GetUserPermissionsRequest';

export const GET_SERVICES = '/data/Service';

export const GET_CLASSIFICATION_OVERVIEW_REQUEST = '/data/GetClassificationOverviewRequest';
export const GET_CLASSIFICATION_EXCEPTION_OVERVIEW_REQUEST = '/data/GetClassificationExceptionOverviewRequest';
export const GET_MANAGER_OVERVIEW_REQUEST = '/data/GetManagerOverviewRequest';
export const GET_REVOCATION_OVERVIEW_REQUEST = '/data/GetRevocationOverviewRequest';
export const GET_REVOCATION_EXCEPTION_OVERVIEW_REQUEST = '/data/GetRevocationExceptionOverviewRequest';
export const GET_MANUAL_OVERVIEW_REQUEST = '/data/GetManualOverviewRequest';

export const ADD_ATTESTATION_REQUEST = '/data/AddAttestationRequest';

export const GET_USER_DIRECT_REPORTS_REQUEST = '/data/GetUserDirectReportsRequest';

export const GET_MANUAL_REVIEW_ITEM_REQUEST = '/data/ManualReviewItem';

export const GET_MANUAL_REVIEW_ATTACHMENT_REQUEST = '/data/ManualReviewAttachment';

export const UPDATE_MANUAL_REVIEW_EVIDENCE_REQUEST = '/data/UpdateManualReviewEvidenceRequest';

export const GET_UAR_UI_STRINGS_REQUEST = '/data/UarUiStrings';

export const GET_UAR_METAINFO_REQUEST = '/data/GetUarMetaInfoRequest';

export const DELEGATE_MANUAL_REVIEW_ITEMS_REQUEST = '/data/DelegateManualReviewItemsRequest';

export const SKIP_MANUAL_REVIEW_EVIDENCE_REQUEST = '/data/SkipManualReviewEvidenceRequest';

export const MAKE_MANUAL_REVIEW_EVIDENCE_INSPECTION_DECISION = '/data/SetEvidenceInspectionResultRequest';

export const GET_FILTERS = '/data/GetFiltersRequest';

export const GET_NARROWED_MANUAL_REVIEW_ITEMS_REQUEST = '/data/NarrowedManualReviewItem';

export const GET_NARROWED_CLASSIFICATION_REVIEW_RESOURCE_REQUEST = '/data/NarrowedClassificationReviewResource';

export const GET_PREVIOUS_CLASSIFICATION_REVIEW_DECISION_REQUEST = '/data/GetPreviousClassificationReviewDecisionRequest';

export const GET_PREVIOUS_MANAGER_REVIEW_DECISION_REQUEST = '/data/GetPreviousManagerReviewDecisionRequest';

export const GET_NARROWED_MANAGER_REVIEW_REQUEST = '/data/NarrowedManagerReviewPermission';

export const GET_NARROWED_REVOCATION_REVIEW_REQUEST = '/data/NarrowedRevocationReviewPermission';

export const UARApi = {
  // -----------------------------------------------------------------------------------------------------------------
  /** All services */
  async getServices(): Promise<UarService[]> {
    return await get<UarService[]>(
      GET_SERVICES,
    );
  },

  // -----------------------------------------------------------------------------------------------------------------
  /** Review Management */

  async getReviewIterations(): Promise<UarIteration[]> {
    const odataQuery = buildODataQuery({
      orderBy: 'StartDate desc',
    });

    const config = {
      odataQuery
    };

    return await get<UarIteration[]>(
      GET_REVIEW_ITERATION_INFO,
      config,
    );
  },

  async postIterationRequest(data: AddIterationRequest) {
    await post<AddIterationRequest, UarIteration>(
      POST_ITERATION_REQUEST,
      data,
    );
  },

  // -----------------------------------------------------------------------------------------------------------------
  /** Missing Resource */

  async addMissingResource(data: AddMissingResourceRequest) {
    await post<AddMissingResourceRequest, MissingResource>(
      POST_MISSING_RESOURCE_REQUEST,
      data,
    );
  },

  async updateManualResourceRequest(data: UpdateManualResourceRequest) {
    await post<UpdateManualResourceRequest, MissingResource>(
      POST_UPDATE_RESOURCE_REQUEST,
      data,
    );
  },

  async deleteResource(data: DeleteResourceRequest) {
    await post<DeleteResourceRequest, MissingResource>(
      POST_DELETE_RESOURCE_REQUEST,
      data,
    );
  },

  async validateResource(data: ValidateResourceRequest) {
    return await post<ValidateResourceRequest, ValidateResourceResponse>(
      POST_Validate_RESOURCE_REQUEST,
      data,
    );
  },

  async getResourceInformation() {
    return await post<any, ResourceInformation[]>(
      GET_RESOURCE_INFOMATION_REQUEST,
      {}
    );
  },

  // -----------------------------------------------------------------------------------------------------------------
  /** Classification Review */

  async getManualResource(ResourceGuid: string | undefined, ResourceIdentifier?: string): Promise<ManualResource[]> {
    const odataConfig = {
      filter: {
        ResourceGuid: {},
        ResourceIdentifier: {}
      }
    };

    if (!isNil(ResourceGuid)) {
      odataConfig.filter.ResourceGuid = {
        value: ResourceGuid,
        type: 'guid'
      };
    }

    if (!isNil(ResourceIdentifier)) {
      odataConfig.filter.ResourceIdentifier = ResourceIdentifier;
    }

    const odataQuery = buildODataQuery(odataConfig);


    const config = {
      odataQuery
    };

    return await get<ManualResource[]>(
      GET_MANUAL_RESOURCE,
      config,
    );
  },

  async getClassificationReviewServices(IterationGuid: string, ServiceTreeId?: string): Promise<UarClassificationReviewService[]> {

    const odataConfig = {
      filter: {
        UserAccessReviewGuid: {
          value: IterationGuid,
          type: 'guid'
        },
        ServiceTreeId: {}
      }
    };

    if (ServiceTreeId !== undefined) {
      odataConfig.filter.ServiceTreeId = {
        value: ServiceTreeId,
        type: 'guid'
      };
    }

    const odataQuery = buildODataQuery(odataConfig);

    const config = {
      odataQuery
    };

    return await get<UarClassificationReviewService[]>(
      GET_CLASSIFICATION_REVIEW_SERVICES,
      config,
    );
  },

  async getClassificationReviewServicesAssignTo(IterationGuid: string,
    AssignToAccountGuid?: string): Promise<UarClassificationReviewService[]> {
    const odataConfig = {
      filter: {
        UserAccessReviewGuid: {
          value: IterationGuid,
          type: 'guid'
        },
        AssignToAccountGuid: {}
      }
    };

    if (!isNil(AssignToAccountGuid)) {
      odataConfig.filter.AssignToAccountGuid = {
        value: AssignToAccountGuid,
        type: 'guid'
      };
    }

    const odataQuery = buildODataQuery(odataConfig);


    const config = {
      odataQuery
    };

    return await get<UarClassificationReviewService[]>(
      GET_CLASSIFICATION_REVIEW_SERVICES_ASSIGN_TO,
      config,
    );
  },

  async getClassificationReviewResources(
    ServiceId: string,
    IterationGuid: string,
    ResourceGuid?: string,
    AssignToAccountGuid?: string
  ): Promise<UarClassificationReviewResource[]> {

    const queryOptions = {
      filter: {
        UserAccessReviewGuid: {
          value: IterationGuid,
          type: 'guid'
        },
        ServiceTreeId: {
          value: ServiceId,
          type: 'guid'
        },
        ResourceGuid: {},
        AssignToAccountGuid: {},
      },
      orderBy: 'ResourceTypeName asc,  ResourceName asc'
    };

    if (ResourceGuid) {
      queryOptions.filter.ResourceGuid = {
        value: ResourceGuid,
        type: 'guid'
      };
    }

    if (!isNil(AssignToAccountGuid)) {
      queryOptions.filter.AssignToAccountGuid = {
        value: AssignToAccountGuid,
        type: 'guid'
      };
    }

    const odataQuery = buildODataQuery(queryOptions);

    const config = {
      odataQuery
    };

    return await get<UarClassificationReviewResource[]>(
      GET_CLASSIFICATION_REVIEW_RESOURCES,
      config,
    );
  },

  async delegateResources(body: DelegateResourcesPayload): Promise<void> {
    return await post<DelegateResourcesPayload, void>(
      DELEGATE_CLASSIFICATION_REVIEW_RESOURCES,
      body,
    );
  },

  async makeClassificationReviewDecision(body: MakeClassificationReviewDecisionBody): Promise<void> {
    return await post<MakeClassificationReviewDecisionBody, void>(
      MAKE_CLASSIFICATION_REVIEW_DECISION,
      body,
    );
  },

  async getClassificationReviewAttachment(ServiceResourceClassificationReviewGuid: string,
  ): Promise<ClassificationReviewAttachment[]> {
    const odataConfig = {
      filter: {
        ServiceResourceClassificationReviewGuid: {
          value: ServiceResourceClassificationReviewGuid,
          type: 'guid'
        }
      }
    };

    const odataQuery = buildODataQuery(odataConfig);

    const config = {
      odataQuery
    };

    return await get<ClassificationReviewAttachment[]>(
      GET_CLASSFICATION_REVIEW_ATTACHMENT,
      config,
    );
  },

  // -----------------------------------------------------------------------------------------------------------------
  /** Manager Review */

  async getManagerReview(IterationGuid: string, AssignToAccountGuid?: string): Promise<UarManagerReview[]> {
    const queryOptions = {
      filter: {
        UserAccessReviewGuid: {
          value: IterationGuid,
          type: 'guid'
        },
        AssignToAccountGuid: {}
      }
    };

    if (!isNil(AssignToAccountGuid)) {
      queryOptions.filter.AssignToAccountGuid = {
        value: AssignToAccountGuid,
        type: 'guid'
      };
    }

    const odataQuery = buildODataQuery(queryOptions);

    const config = {
      odataQuery
    };

    return await get<UarManagerReview[]>(
      GET_MANAGER_REVIEW,
      config,
    );
  },

  async delegateUserPermissions(body: DelegateUserPermissions): Promise<void> {
    return await post<DelegateUserPermissions, void>(
      DELEGATE_USER_PERMISSIONS,
      body,
    );
  },

  async makeManagerReviewDecision(body: ManagerReviewDecisionPayload): Promise<void> {
    return await post<ManagerReviewDecisionPayload, void>(
      MAKE_MANAGER_REVIEW_DECISION,
      body,
    );
  },

  // -----------------------------------------------------------------------------------------------------------------
  /** Revocation Review */

  async getRevocationReview(IterationGuid: string, AssignToAccountGuid?: string): Promise<UarRevocationReview[]> {
    const queryOptions = {
      filter: {
        UserAccessReviewGuid: {
          value: IterationGuid,
          type: 'guid'
        },
        AssignToAccountGuid: {}
      }
    };

    if (!isNil(AssignToAccountGuid)) {
      queryOptions.filter.AssignToAccountGuid = {
        value: AssignToAccountGuid,
        type: 'guid'
      };
    }

    const odataQuery = buildODataQuery(queryOptions);

    const config = {
      odataQuery
    };

    return await get<UarRevocationReview[]>(
      GET_REVOCATION_REVIEW,
      config,
    );
  },

  async delegateRevocationPermissions(body: DelegateRevocationPermissions): Promise<void> {
    return await post<DelegateRevocationPermissions, void>(
      DELEGATE_REVOCATION_PERMISSIONS,
      body
    );
  },

  async makeRevocationReviewDecision(body: RevocationReviewDecisionPayload): Promise<void> {
    return await post<RevocationReviewDecisionPayload, void>(
      MAKE_REVOCATION_REVIEW_DECISION,
      body,
    );
  },

  async getRevocationReviewAccessDetails(body: UarPermissionAccessDetailsPayload): Promise<UarPermissionAccessDetails[]> {
    return await post<UarPermissionAccessDetailsPayload, UarPermissionAccessDetails[]>(
      GET_REVOCATION_REVIEW_PERMISSION_ACCESS_PATHS_REQUEST,
      body,
    );
  },

  async getRevocationReviewAdditionalDetails(body: UarRevocationReviewAdditionalDetailsPayload)
    : Promise<UarRevocationReviewAdditionalDetails[]> {
    return await post<UarRevocationReviewAdditionalDetailsPayload, UarRevocationReviewAdditionalDetails[]>(
      GET_REVOCATION_REVIEW_ADDITIONAL_DETAILS_REQUEST,
      body,
    );
  },

  // -----------------------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------------------
  /** Exception Review */

  async getClassificationException(payload: UarClassificationExceptionPayload | undefined): Promise<UarClassificationExceptionResource[]> {

    const queryOptions: any = {
      filter: {
        ResourceGuid: {},
        ServiceTreeId: {},
        UserAccessReviewGuid: {}
      }
    };

    if (payload?.ResourceGuid) {
      queryOptions.filter.ResourceGuid = {
        value: payload.ResourceGuid,
        type: 'guid'
      };
    }

    if (payload?.ServiceTreeId) {
      queryOptions.filter.ServiceTreeId = {
        value: payload.ServiceTreeId,
        type: 'guid'
      };
    }

    if (payload?.IterationGuids) {
      queryOptions.filter.UserAccessReviewGuid = {
        in: {
          value: payload.IterationGuids,
          type: 'guid'
        }
      };
    }

    const odataQuery = buildODataQuery(queryOptions);

    const config = {
      odataQuery
    };

    return await get<UarClassificationExceptionResource[]>(
      GET_CR_EXCEPTION,
      config,
    );
  },

  async makeClassificationExceptionDecisionPayload(body: MakeClassificationExceptionDecisionBody): Promise<void> {
    return await post<MakeClassificationExceptionDecisionBody, void>(
      MAKE_CR_EXCEPTION_DECISION,
      body,
    );
  },

  // -----------------------------------------------------------------------------------------------------------------

  async getRevocationReviewException(payload: UarRevocationExceptionPayload): Promise<UarRevocationReview[]> {
    const queryOptions: any = {
      filter: {
        UserAccessReviewGuid: {}
      }
    };

    if (payload?.iterationGuids) {
      queryOptions.filter.UserAccessReviewGuid = {
        in: {
          value: payload.iterationGuids,
          type: 'guid'
        }
      };
    }

    const odataQuery = buildODataQuery(queryOptions);

    const config = {
      odataQuery
    };


    return await get<UarRevocationReview[]>(
      GET_RR_EXCEPTION,
      config
    );
  },

  async makeRevocationReviewExceptionDecision(body: RevocationReviewDecisionPayload<RevocationReviewExceptionDecisionItem>): Promise<void> {
    return await post<RevocationReviewDecisionPayload<RevocationReviewExceptionDecisionItem>, void>(
      MAKE_RR_EXCEPTION_DECISION,
      body,
    );
  },

  // -----------------------------------------------------------------------------------------------------------------
  /** UAR Permissions*/

  async getUserPermissions(): Promise<UarPermissions> {
    return await post<any, UarPermissions>(
      GET_USER_PERMISSIONS_REQUEST,
      {},
    );
  },

  async getClassificationOverview(): Promise<AthenaMyReviewResult[]> {
    return await post<any, AthenaMyReviewResult[]>(
      GET_CLASSIFICATION_OVERVIEW_REQUEST,
      {},
    );
  },

  async getClassificationExceptionOverview(): Promise<AthenaMyReviewResult[]> {
    return await post<any, AthenaMyReviewResult[]>(
      GET_CLASSIFICATION_EXCEPTION_OVERVIEW_REQUEST,
      {},
    );
  },

  async getManagerOverview(): Promise<AthenaMyReviewResult[]> {
    return await post<any, AthenaMyReviewResult[]>(
      GET_MANAGER_OVERVIEW_REQUEST,
      {},
    );
  },

  async getRevocationOverview(): Promise<AthenaMyReviewResult[]> {
    return await post<any, AthenaMyReviewResult[]>(
      GET_REVOCATION_OVERVIEW_REQUEST,
      {},
    );
  },

  async getRevocationExceptionOverview(): Promise<AthenaMyReviewResult[]> {
    return await post<any, AthenaMyReviewResult[]>(
      GET_REVOCATION_EXCEPTION_OVERVIEW_REQUEST,
      {},
    );
  },

  async addAttestation(body: AddClassificationAttestBody): Promise<void> {
    return await post<AddClassificationAttestBody, void>(
      ADD_ATTESTATION_REQUEST,
      body,
    );
  },

  async getUserDirectReports(): Promise<UserAccount[]> {
    return await post<any, UserAccount[]>(
      GET_USER_DIRECT_REPORTS_REQUEST,
      {},
    );
  },

  async getManualOverview(): Promise<AthenaMyReviewResult[]> {
    return await post<any, AthenaMyReviewResult[]>(
      GET_MANUAL_OVERVIEW_REQUEST,
      {},
    );
  },

  async getManualReviewItem(payload: UarGetManualReviewItemRequestPayload): Promise<AthenaManualReviewActionItem[]> {
    const odataConfig = {
      filter: {
        UserAccessReviewGuid: {
          value: payload.IterationGuid,
          type: 'guid'
        },
        ServiceTreeId: {
          value: payload.ServiceId,
          type: 'guid'
        },
        AssignToAccountGuid: {}
      }
    };

    if (!isNil(payload.AssignToAccountGuid)) {
      odataConfig.filter.AssignToAccountGuid = {
        value: payload.AssignToAccountGuid,
        type: 'guid'
      };
    }

    const odataQuery = buildODataQuery(odataConfig);

    const config = {
      odataQuery
    };


    return await get<AthenaManualReviewActionItem[]>(
      GET_MANUAL_REVIEW_ITEM_REQUEST,
      config
    );
  },

  async getManualReviewAttachments(payload: UarGetManualReviewAttachmentRequestPayload): Promise<ManualReviewAttachment[]> {
    const odataConfig = {
      filter: {
        ManualReviewGuid: {
          value: payload.ManualReviewGuid,
          type: 'guid'
        }
      }
    };

    const odataQuery = buildODataQuery(odataConfig);

    const config = {
      odataQuery
    };

    return await get<ManualReviewAttachment[]>(
      GET_MANUAL_REVIEW_ATTACHMENT_REQUEST,
      config
    );
  },

  async updateManualReviewEvidence(payload: UarUpdateManualReviewEvidenceRequestPayload): Promise<void> {
    return await post<UarUpdateManualReviewEvidenceRequestPayload, void>(
      UPDATE_MANUAL_REVIEW_EVIDENCE_REQUEST,
      payload
    );
  },

  async getUARUIStrings(): Promise<UARUIString[]> {
    return await get<UARUIString[]>(
      GET_UAR_UI_STRINGS_REQUEST,
    );
  },

  async getUarMetaInfo(): Promise<UarMetaInfo> {
    return await post<any, UarMetaInfo>(
      GET_UAR_METAINFO_REQUEST,
      {},
    );
  },

  async delegateManualReviewItems(payload: UarDelegateManualReviewItemsRequestPayload): Promise<void> {
    return await post<UarDelegateManualReviewItemsRequestPayload, void>(
      DELEGATE_MANUAL_REVIEW_ITEMS_REQUEST,
      payload
    );
  },

  async skipManualReviewEvidence(payload: UarSkipManualReviewEvidenceRequestPayload): Promise<void> {
    return await post<UarSkipManualReviewEvidenceRequestPayload, void>(
      SKIP_MANUAL_REVIEW_EVIDENCE_REQUEST,
      payload
    );
  },

  async makeEvidenceInspectionDecision(body: UarEvidenceInspectionDecisionPayload): Promise<void> {
    return await post<UarEvidenceInspectionDecisionPayload, void>(
      MAKE_MANUAL_REVIEW_EVIDENCE_INSPECTION_DECISION,
      body,
    );
  },

  // -----------------------------------------------------------------------------------------------------------------

  async getNarrowedManualReviewItems(
    filters: Record<string, any>,
    filterFields: AthenaTriageFilterField[]
  ): Promise<AthenaManualReviewActionItem[]> {
    const filterValue = parseTriageFilterFields(filters, filterFields);
    const odataConfig = {
      filter: filterValue
    };

    const odataQuery = buildODataQuery(odataConfig);

    const config = {
      odataQuery
    };

    return await get<AthenaManualReviewActionItem[]>(
      GET_NARROWED_MANUAL_REVIEW_ITEMS_REQUEST,
      config
    );
  },

  async getNarrowedClassificationReviewItems(
    filters: Record<string, any>,
    filterFields: AthenaTriageFilterField[]
  ): Promise<UarClassificationReviewResource[]> {
    const filterValue: any = parseTriageFilterFields(filters, filterFields);

    const odataConfig = {
      filter: filterValue
    };

    const odataQuery = buildODataQuery(odataConfig);

    const config = {
      odataQuery
    };

    return await get<UarClassificationReviewResource[]>(
      GET_NARROWED_CLASSIFICATION_REVIEW_RESOURCE_REQUEST,
      config
    );
  },

  async getFilterFields(iterationId: string, stage: AthenaIterationStageName): Promise<AthenaTriageFilterField[]> {
    return await post<any, AthenaTriageFilterField[]>(
      GET_FILTERS, {
        userAccessReviewGuid: iterationId,
        stageType: stage
      });
  },

  async getClassificationReviewServiceDecisions( itemGuids: string[]): Promise<UarClassificationReviewServiceDecision[]> {
    return await post<any, UarClassificationReviewServiceDecision[]>(
      GET_PREVIOUS_CLASSIFICATION_REVIEW_DECISION_REQUEST,
      {
        previousClassificationReviewDecisionGuids: itemGuids
      });
  },

  async getManagerReviewPreviousDecisions(itemGuids: string[]): Promise<UarManagerReviewPreviousDecision[]> {
    return await post<any, UarManagerReviewPreviousDecision[]>(
      GET_PREVIOUS_MANAGER_REVIEW_DECISION_REQUEST,
      {
        userPermissionReviewGuids: itemGuids
      });
  },

  async getNarrowedManagerReviewItems(
    filters: Record<string, any>,
    filterFields: AthenaTriageFilterField[]
  ): Promise<UarManagerReview[]> {
    const filterValue: any = parseTriageFilterFields(filters, filterFields);

    const odataConfig = {
      filter: filterValue
    };

    const odataQuery = buildODataQuery(odataConfig);

    const config = {
      odataQuery
    };

    return await get<UarManagerReview[]>(
      GET_NARROWED_MANAGER_REVIEW_REQUEST,
      config
    );
  },

  async getNarrowedRevocationReviewItems(
    filters: Record<string, any>,
    filterFields: AthenaTriageFilterField[]
  ): Promise<UarRevocationReview[]> {
    const filterValue: any = parseTriageFilterFields(filters, filterFields);

    const odataConfig = {
      filter: filterValue
    };

    const odataQuery = buildODataQuery(odataConfig);

    const config = {
      odataQuery
    };

    return await get<UarRevocationReview[]>(
      GET_NARROWED_REVOCATION_REVIEW_REQUEST,
      config
    );
  }
};
